import { Component, OnInit, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
import { MasterService } from '../../../_services/master.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
// import {indicate} from 'operators';
import { Observable, Subject, defer, timer } from 'rxjs';
import { takeWhile, take, finalize } from 'rxjs/operators';

export const prepare = <T>(callback: () => void) => {
	return (source: Observable<T>): Observable<T> =>
		defer(() => {
			callback();
			return source;
		});
};

export const indicate = <T>(indicator: Subject<boolean>) => {
	let alive = true;
	return (source: Observable<T>): Observable<T> =>
		source.pipe(
			prepare(() =>
				timer(500)
					.pipe(
						takeWhile(() => alive),
						take(1)
					)
					.subscribe(() => {
						indicator.next(true);
					})
			),
			finalize(() => {
				alive = false;
				indicator.next(false);
			})
		);
};


export interface Tutorial {
  statustracking?: any;
  state?: any;
  id?: any;
  manifest_date?: string,
  invoice_no?: string,
  mawb?: string,
  flight_number?: string,
  bag_code?: string,

  // flight_number?: string,
  flight_id?: string,
  vendor_id?: string,
  note?: string,
  status?: string,
  namevendors?: string,
  mawbflight?: string,
  // metode_bayar?: string,
  isStatusTransit?: string,
  keberangkatan?: string,
  kedatangan?: string,
  tgl_keberangkatan?: string,
  tgl_kedatangan?: string,
  jam_keberangkatan?: string,
  jam_kedatangan?: string,
  keberangkatan_t?: string;
  kedatangan_t?: string;
  tgl_keberangkatan_t?: string;
  tgl_kedatangan_t?: string;
  jam_keberangkatan_t?: string;
  jam_kedatangan_t?: string;
  // name?: string,
  // description?: string,
  // note?: string,
  // published?: boolean;
  action?: string;
}

@Component({
  selector: 'app-tr-manifest',
  templateUrl: './tr-manifest.component.html',
  styleUrls: ['./tr-manifest.component.css']
})
export class TrManifestComponent implements OnInit {
  loading$ = new Subject<boolean>()
  // exampleData;
  // public exampleData: Array<Select2OptionData>;
  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100, 500, 1000, 10000];
  isCreated:boolean = true;
  // vcombo: any;
  vcombo:any = [];

  vcomboss:any = [];
  vcombosflight:any = [];

  manifest_date = '';
  vendor_id = '';
  flight_id = '';
  status: string | undefined;
  // statusopen: boolean;
  statusopen: boolean | undefined;

  //Select2

  // public options: Options;
  // public options2: Options;

  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private tokenStorage: TokenStorageService,
    ) { }

  ngOnInit(): void {
    this.retrieveTutorials();
    this.retrieveCombodata();
    this.retrieveCombodatavendors();
    this.retrieveCombodataflights();
    this.getUserData();
  }

  usersData:any = {};
	rolesString:any = '';
  isAdmin:boolean = false;
  isSuperadmin:boolean = false;
  isCustomer:boolean = false;
  getUserData() {
    this.tokenStorage.getUsers().then(async res => {
      this.usersData = res;
      // console.log(res)
		  this.usersData.role.forEach((e:any) => {
			this.rolesString = this.rolesString+e.name+', ';
		  });
			// console.log(this.rolesString)
      if(this.rolesString.includes('Admin')) {
        this.isAdmin = true;
      }
      if(this.rolesString.includes('Superadmin')) {
        this.isSuperadmin = true;
      }
			// console.log(this.isAdmin)
    })
  }


  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    this.fadd_role.id = '';
    this.fadd_role.manifest_date = '';
    this.fadd_role.mawb = '';
    this.fadd_role.flight_number = '';
    this.fadd_role.vendor_id = '';
    this.fadd_role.note = '';
    this.fadd_role.bag_code = '';
    this.fadd_role.status = '';
    this.fadd_role.keberangkatan = '';
    this.fadd_role.kedatangan = '';
    this.fadd_role.tgl_keberangkatan = '';
    this.fadd_role.tgl_kedatangan = '';
    this.fadd_role.jam_keberangkatan = '';
    this.fadd_role.jam_kedatangan = '';
    this.fadd_role.keberangkatan_t = '';
    this.fadd_role.kedatangan_t = '';
    this.fadd_role.tgl_keberangkatan_t = '';
    this.fadd_role.tgl_kedatangan_t = '';
    this.fadd_role.jam_keberangkatan_t = '';
    this.fadd_role.jam_kedatangan_t = '';
    this.fadd_role.isStatusTransit = '';
		// this.modalService.open(content);
    this.modalService.open(content, { size: 'lg' });
	}

  openfilter(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    // this.shipper_id = '';
    this.manifest_date = '';
    this.vendor_id = '';

    // this.ship_date = '';
    // this.vendor_id = '';
		this.modalService.open(content, { size: 'lg' });
    // this.modalService.open(content, { size: 'lg' }).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
	}

  opensetstatustracking(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    // this.shipper_id = '';
    this.status = '';

    // this.ship_date = '';
    // this.vendor_id = '';
		this.modalService.open(content);
    // this.modalService.open(content, { size: 'lg' }).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
	}

  // open(content:any) {
  //   const dialogRef = this.dialog.open(content, {
  //     width: '500px',
  //     data: { adminData: r }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.content = [];
  //     this.getDataAdmin();
  //   });
  // }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    params.vendor_id = this.vendor_id;
    params.manifest_date = this.manifest_date;

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('manifests/view', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  retrieveCombodata(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('vendors/view', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcombo = data;
        // console.log(this.vcombo, 'this.vcombo');
        // this.count = totalItems;
        // console.log(response);
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  retrieveCombodatavendors(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('vendors/combodata', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcomboss = data;
        // console.log(this.vcombo, 'this.vcombo');
        // this.count = totalItems;
        // console.log(response);
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  retrieveCombodataflights(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('flights/view', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcombosflight = data;
        // console.log(this.vcombo, 'this.vcombo');
        // this.count = totalItems;
        // console.log(response);
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.modalService.dismissAll();
    this.retrieveTutorials();
  }

  async downloadExcel() {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    params.vendor_id = this.vendor_id;
    params.manifest_date = this.manifest_date;
    // console.log(params, 'params')

    this.tutorialService.postData('manifests/downloadexcel', params)
      .subscribe(
        async response => {
          // console.log(response, 'resp');

              // var data = response.data,
        // blob = new Blob([data], { type: response.headers('content-type') })
        // var file = new Blob([response], {
        //     type: 'application/vnd.ms-excel'
        // });
        // var fileURL = URL.createObjectURL(file);
        var anchor = document.createElement('a');
        // anchor.href = 'https://apiexpl.hallo.or.id/uploads/files/manifest/'+response.message;
        anchor.href = 'https://api.nissaexpress.com/uploads/files/manifest/'+response.message;
        // anchor.href = 'http://localhost:39998/uploads/files/manifest/'+response.message;
        // anchor.href = 'http://localhost:7777/uploads/files/manifest/'+response.message;
        anchor.target="_blank";
        anchor.click();
        // var a = document.createElement('a');
        // a.href = response.message;
        // a.download = new Date().toISOString().slice(0, 10) + '_' + Math.floor(Math.random() * 9999) + '_laporanmanifest';
        // document.body.appendChild(a);
        // a.click();

          // this.fadd_role = {};
          this.presentToast('Generate Excel Manifest Success', 'success');
          // this.submitted = true;
          this.modalService.dismissAll();
          this.retrieveTutorials();
        },
        async error => {
          // console.log(error);
          this.presentToast(error.error.errors, 'error');
        });

  };

  async syncroneStatus() {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    params.vendor_id = this.vendor_id;
    params.manifest_date = this.manifest_date;
    // console.log(params, 'params')

    this.tutorialService.postData('manifests/syncstatus', params)
    .pipe(
      indicate(this.loading$))
      .subscribe(
        async response => {
          this.presentToast('Status Last Update Success', 'success');
          // this.submitted = true;
          this.modalService.dismissAll();
          this.retrieveTutorials();
        },
        async error => {
          // console.log(error);
          this.presentToast(error.error.errors, 'error');
        });

  }

  fadd_role: Tutorial = {
    manifest_date:'',
    invoice_no:'',
    mawb:'',
    flight_number:'',
    vendor_id:'',
    flight_id:'',
    note:'',
    bag_code:'',
    status:'',
    // description: '',
    // published: false
  };

  submitted!:boolean;
  saveTutorial(): void {
    const data = {
      manifest_date: this.fadd_role.manifest_date,
      // invoice_no: this.fadd_role.invoice_no,
      mawb: this.fadd_role.mawb,
      bag_code: this.fadd_role.bag_code,
      flight_number: this.fadd_role.flight_number,
      // flight_id: this.fadd_role.flight_id,
      vendor_id: this.fadd_role.vendor_id,
      note: this.fadd_role.note,
      status: this.fadd_role.status,
      keberangkatan: this.fadd_role.keberangkatan,
      kedatangan: this.fadd_role.kedatangan,
      tgl_keberangkatan: this.fadd_role.tgl_keberangkatan,
      tgl_kedatangan: this.fadd_role.tgl_kedatangan,
      jam_keberangkatan: this.fadd_role.jam_keberangkatan,
      jam_kedatangan: this.fadd_role.jam_kedatangan,
      keberangkatan_t: this.fadd_role.keberangkatan_t,
      kedatangan_t: this.fadd_role.kedatangan_t,
      tgl_keberangkatan_t: this.fadd_role.tgl_keberangkatan_t,
      tgl_kedatangan_t: this.fadd_role.tgl_kedatangan_t,
      jam_keberangkatan_t: this.fadd_role.jam_keberangkatan_t,
      jam_kedatangan_t: this.fadd_role.jam_kedatangan_t,
      isStatusTransit: this.fadd_role.isStatusTransit,
    };
    // console.log(data, 'data');

    if(this.isCreated) {
      // if(data.flight_id == ''){
      //   this.toastService.show('Maaf Flight Tidak Boleh Kosong', { classname: 'bg-danger text-white', delay: 6000 });
      // }else{
        this.tutorialService.postData('manifests/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
      // }
      // console.log(data.flight_id, 'data.flight_id')
      // console.log(data.flight_id == '', 'data.flight_id')
    } else {
      this.tutorialService.postData('manifests/update/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal, { size: 'lg' });
    this.fadd_role.id = tutorial.id;
    this.fadd_role.manifest_date = tutorial.manifest_date;
    this.fadd_role.invoice_no = tutorial.invoice_no;
    this.fadd_role.mawb = tutorial.mawb;
    this.fadd_role.bag_code = tutorial.bag_code;
    this.fadd_role.flight_number = tutorial.flight_number;
    // this.fadd_role.flight_id = tutorial.flight_id;
    this.fadd_role.vendor_id = tutorial.vendor_id;
    this.fadd_role.note = tutorial.note;
    this.fadd_role.status = tutorial.status;
    this.fadd_role.keberangkatan = tutorial.keberangkatan;
    this.fadd_role.kedatangan = tutorial.kedatangan;
    this.fadd_role.tgl_keberangkatan = tutorial.tgl_keberangkatan;
    this.fadd_role.tgl_kedatangan = tutorial.tgl_kedatangan;
    this.fadd_role.jam_keberangkatan = tutorial.jam_keberangkatan;
    this.fadd_role.jam_kedatangan = tutorial.jam_kedatangan;
    this.fadd_role.keberangkatan_t = tutorial.keberangkatan_t;
    this.fadd_role.kedatangan_t = tutorial.kedatangan_t;
    this.fadd_role.tgl_keberangkatan_t = tutorial.tgl_keberangkatan_t;
    this.fadd_role.tgl_kedatangan_t = tutorial.tgl_kedatangan_t;
    this.fadd_role.jam_keberangkatan_t = tutorial.jam_keberangkatan_t;
    this.fadd_role.jam_kedatangan_t = tutorial.jam_kedatangan_t;
    // this.fadd_role.tanggal_flight = tutorial.tanggal_flight;
    // if(tutorial.isStatusTransit == 'Ya'){
    if(tutorial.isStatusTransit === 'Ya'){
      this.fadd_role.isStatusTransit = 'Ya';
      // console.log('function called is tunai');
      this.statusopen = true;
    } else {
      this.fadd_role.isStatusTransit = 'Tidak';
      // console.log('function called is transfer');
      this.statusopen = false;
    }
    console.log(tutorial.isStatusTransit,'tutorial.isStatusTransit')
    this.fadd_role.action = action;
  }

  deleteUser(data:any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      // const sendataid = {
      //   id: data.id,
      // };
      this.tutorialService.delete(`manifests/delete/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            // this.fadd_role = {};
            this.presentToast(response.message, 'success');
            // this.submitted = true;
            // this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
      // console.log(conf, data.id)
    }
  }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 6000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 6000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

  checkUncheckAll(event:any) {
      var checkboxes = document.getElementsByTagName('input');
      if (event.target.checked) {
          for (var i = 0; i < checkboxes.length; i++) {
            // console.log(i, '1')
              if (checkboxes[i].type == 'checkbox') {
                  checkboxes[i].checked = true;
              }
          }
      } else {
          for (var i = 0; i < checkboxes.length; i++) {
              // console.log(i, '2')
              if (checkboxes[i].type == 'checkbox') {
                  checkboxes[i].checked = false;
              }
          }
      }
  }

  checkAll(ev: any) {
    this.tutorials.forEach(x => x.state = ev.target.checked)
  }

  isAllChecked() {
    return this.tutorials.every(_ => _.state);
  }

  check(tutorial: any) {
    tutorial.state = !tutorial.state;
  }

  setStatus() {
    this.tutorials.forEach(x => {
      if(x.state) {
        const params = x;
        params.statustracking = this.status;
        // params.manifest_date = this.manifest_date;
        // console.log(x, this.status, 'x, this.status,');
        // alert(x.tutorial);
        this.tutorialService.postData('manifests/setstatustracking', params)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
      }
    });
  }
  
  onChange(deviceValue: any) {
    // console.log(deviceValue.value);
    if(deviceValue.value === 'Tidak'){
      // console.log('function called is tunai');
      this.statusopen = false;
    } else {
      // console.log('function called is transfer');
      this.statusopen = true;
    }
}

}
