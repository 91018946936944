<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <!-- row -->
  <div class="container-fluid">
    <!-- <div class="row"> -->

      <!-- <div class="col-xl-4 col-sm-6" *ngIf="isSuperadmin">
        <div class="card border coin-holding-card border-info">
          <div class="card-body bg-info rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Payment</h4>
                <span class="text-white fs-14 op7">By Month {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalpayments}}</h4>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-xl-4 col-sm-6">
        <div class="card border coin-holding-card border-danger">
          <div class="card-body bg-danger rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Bill</h4>
                <span class="text-white fs-14 op7">By Month {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalbill}}</h4>
            </div>
          </div>
        </div>
      </div> -->
    <!-- </div> -->
    
    <div class="row">
      <div class="col-xl-4 col-sm-4">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body bg-warning rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Inbound</h4>
                <span class="text-white fs-14 op7">Hari ini {{datenow | date : 'd MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalinbh}}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-sm-4">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body bg-warning rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Inbound</h4>
                <span class="text-white fs-14 op7">Bulan {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalinbb}}</h4>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-xl-4 col-sm-4">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body bg-warning rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Inbound</h4>
                <span class="text-white fs-14 op7">Tahun {{datenow | date : 'y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalinbt}}</h4>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="row">
      <div class="col-xl-3 col-sm-3">
        <div class="card border coin-holding-card border-danger">
          <div class="card-body bg-danger rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Shipment Proses</h4>
                <span class="text-white fs-14 op7">Hari ini {{datenow | date : 'd MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshpph}}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-3">
        <div class="card border coin-holding-card border-danger">
          <div class="card-body bg-danger rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Shipment Proses</h4>
                <span class="text-white fs-14 op7">Bulan {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshppb}}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-3">
        <div class="card border coin-holding-card border-success">
          <div class="card-body bg-success rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Shipment Selesai</h4>
                <span class="text-white fs-14 op7">Hari ini {{datenow | date : 'd MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshpph1}}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-3">
        <div class="card border coin-holding-card border-success">
          <div class="card-body bg-success rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Shipment Selesai</h4>
                <span class="text-white fs-14 op7">Bulan {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshppb1}}</h4>
            </div>
          </div>
        </div>
      </div>
     
    </div>

    <div class="row">
      <div class="col-xl-6 col-xxl-6" *ngIf="isSuperadmin">
        <app-graph-market-shipper></app-graph-market-shipper>
      </div>
      
      <div class="col-xl-6 col-xxl-6" *ngIf="isSuperadmin">
        <app-graph-market-country></app-graph-market-country>
      </div>

      <div class="col-xl-6 col-xxl-12" *ngIf="isSuperadmin">
        <app-graph-market-periode></app-graph-market-periode>
      </div>

    </div>
  </div>
</div>
     
<!--**********************************
    Content body end
***********************************-->
