import { assertPlatform, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';

import { MasterService } from '../../../_services/master.service';

import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';

import { TokenStorageService } from 'src/app/_services/token-storage.service';

import { ZXingScannerComponent } from '@zxing/ngx-scanner';

import { Result, BarcodeFormat } from '@zxing/library';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';


export interface Tutorial {
  id?: any;
  code?: string,
  pengirim?: string,
  status_kurir?: string,
  status_pickup?: string,
  status_direct?: string,
  status_agen?: string,
  tgl_kirim?: string,
  // id_kurir?: string,
  name_kurir?: string,
  no_resi?: string,
  id_shippers?: string,
  name_pengirim?: string,
  id_kurirs?: string,
  status_paket?: string,
  tgl_terima?: string,
  berat_paket?: string,
  isi_paket?: string,
  images?: string,
  fileimage?: string,
  fileimage1?: string,
  fileimage2?: string,
  fileimage3?: string,
  status_dikurir?: string,
  description?: string,
  nameshippers?: string,
  id_city?: any;
  type_id?: any;
  subtype_id?: any;
  status_inbound?: any;
  id_partner?: any;
  status_idpartner?: any;
  namakurirs?: any;
  cleansings_id?: any;
  full_name?: any;
  createdAt?: any;
  action?: string
}


@Component({
  selector: 'app-tr-inbound',
  templateUrl: './tr-inbound.component.html',
  styleUrls: ['./tr-inbound.component.css']
})
export class TrInboundComponent implements OnInit {

 inboundImg = 'https://api.nissaexpress.com/uploads/images/inbound/';
//  inboundImg = 'http://localhost:39998/uploads/images/inbound/';
 isLoading$ = false;

 image: File | undefined;
 resData: any;
 selectedFile : any;
 selectedFile1 : any;
 selectedFile2 : any;
 selectedFile3 : any;
//  selectedFile = null;
// SELECT TYPE
  typeItems: any[] = [];
  selectedTypeItem: any | undefined;

  subtypeItems: any[] = [];
  selectedSubTypeItem: any | undefined;

  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100, 500, 1000, 2000, "ALL"];
  isCreated:boolean = true;

  code: any;
  tgl_kirim: any;
  name_kurir: any;
  no_resi: any;
  id_shippers: any;
  name_pengirim: any;
  id_kurirs: any;
  status_paket: any;
  tgl_terima: any;
  berat_paket: any;
  isi_paket: any;
  status_kurir: any;
  pengirim: any;
  status_pickup: any;
  status_direct: any;
  status_agen: any;
  description: any;
  type_id: any;
  subtype_id: any;
  images: any;
  imagess: any;
  imagess1: any;
  imagess2: any;
  imagess3: any;
  fileimage: any;
  fileimage1: any;
  fileimage2: any;
  fileimage3: any;
  nameshippers: any;
  namakurirs: any;
  createdAt: any;
  // phone_number: any;
  // address: any;
  // template_option: any;
  // fileimage: any;
  id: any;

  countries: any[] = [];
  selectedShipperPhoneNumberCountry: any = {};

  action:any = '';
  phonecode: any;

  vcombos:any = [];
  vcomboskurir:any = [];
  myFiles: any = [];

  ngOnInit(): void {
    this.retrieveTutorials();
    // this.fetchDropdownValues();
    this.retrieveCombodatashippers();
    this.retrieveCombodatakurirs();
    // this.retrieveCombodataType();
    this.retrieveCombodatatypeItems();
    // this.retrieveCombodatasubtypeItems();
    this.fetchDropdownValues();
    this.getUserData();
  }

  // START SCAN
   //scan
   availableDevices!: MediaDeviceInfo[];
   currentDevice!: MediaDeviceInfo;

   formatsEnabled: BarcodeFormat[] = [
     BarcodeFormat.CODE_128,
     BarcodeFormat.DATA_MATRIX,
     BarcodeFormat.EAN_13,
     BarcodeFormat.QR_CODE,
   ];

   hasDevices!: boolean;
   hasPermission!: boolean;

   qrResultString!: string;

   torchEnabled = false;
   torchAvailable$ = new BehaviorSubject<boolean>(false);
   tryHarder = false;
  // END SCAN

  submitted!:boolean;
  // event: any;
  angForm: FormGroup | any;
  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private fb: FormBuilder,
    private tokenStorage: TokenStorageService,
    private router: Router
    ) {
      this.createForm();
    }

  usersData:any = {};
	rolesString:any = '';
  isAdmin:boolean = false;
  isSuperadmin:boolean = false;
  isCustomer:boolean = false;
  isAgen:boolean = false;
  isFinance:boolean = false;
  isCleansings:boolean = false;
  isInbound:boolean = false;
  getUserData() {
    this.tokenStorage.getUsers().then(async res => {
      this.usersData = res;
      // console.log(res)
		  this.usersData.role.forEach((e:any) => {
			this.rolesString = this.rolesString+e.name+', ';
		  });
			// console.log(this.rolesString)
      if(this.rolesString.includes('Admin')) {
        this.isAdmin = true;
      }
      if(this.rolesString.includes('Superadmin')) {
        this.isSuperadmin = true;
      }
      if(this.rolesString.includes('Agen')) {
        this.isAgen = true;
      }
      if(this.rolesString.includes('Finance')) {
        this.isFinance = true;
      }
      if(this.rolesString.includes('Cleansings')) {
        this.isCleansings = true;
      }
      if(this.rolesString.includes('Inbound')) {
        this.isInbound = true;
      }
			// console.log(this.isAdmin)
    })
  }

  // openData(tutorial:any, action:any) {
  //   const param: NavigationExtras = {
  //     queryParams: {
  //       info: JSON.stringify(tutorial),
  //       action: JSON.stringify(action)
  //     }
  //   }
  //   this.router.navigate(['/admin/form-cleansing'], param);
  // }

  createForm() {
    this.angForm = this.fb.group({
      //  code: ['', [Validators.required ]],
       tgl_kirim: [null],
       name_kurir: [null],
       no_resi: [null],
       pengirim: [null],
       name_pengirim: [null],
       status_kurir: [],
       status_pickup: [],
       status_direct: [],
       status_agen: [],
       id_shippers: [''],
       id_kurirs: [''],
       status_paket: [''],
       tgl_terima: [''],
       berat_paket: [''],
      //  isi_paket: ['', [Validators.required ]],
       description: [''],
       type_id: [''],
       subtype_id: [''],
      //  template_option: ['', [Validators.required] ]
    });

    // console.log('masuk')

  }

  // START SCAN
  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  namaitems:any;
  namasubitems:any;
  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    // console.log(resultString == '', 'resultString')
    // console.log(resultString != '', '!resultString')
    // console.log(this.qrResultString, 'qrResultString')
    // this.fadd_role.cleansing_id = resultString;
    this.no_resi = resultString;
    this.tutorialService
      .get(`inbound/getresi/${this.no_resi}`)
      .subscribe((data: any) => {
        // console.log(data, 'data3')
        // console.log(data.code, 'data.code')
        // console.log(data.data.code == '', 'data.code')
        // console.log(data.data.code != '', 'data.code')
        // console.log(data, 'data')
        this.code = data.data.code;
        this.id_shippers = data.data.id_shippers;
        this.nameshippers = data.data.nameshippers;
        this.id_kurirs = data.data.id_kurirs;
        this.namakurirs = data.data.namakurirs;
        this.tgl_kirim = data.data.tgl_kirim;
        this.id = data.data.id;
        this.status_paket = data.data.status_paket == 'N' ? "Belum di Terima" : "Terima";
        this.namaitems = data.data.namaitems;
        this.namasubitems = data.data.namasubitems;
        this.berat_paket = data.data.berat_paket;
        this.tgl_terima = data.data.tgl_terima;
        this.description = data.data.description;
      },(error: any) => {
        // console.log(error, 'error1');
        this.presentToast(error.error.errors, 'error');
        // if(error.status ==401){
        //   alert("Login or Password not exist");
        // }
      },
        () => {
        console.log("Complete");
      });

    // this.tutorialService.postData('inbound/getresi/'+this.no_resi, data)
    //   .subscribe(
    //     async response => {
    //       console.log(response);
    //       // this.fadd_role = {};
    //       // this.presentToast(response.message, 'success');
    //       // this.submitted = true;
    //       // this.modalService.dismissAll();
    //       // this.retrieveTutorials();
    //       // this.reloadPage();
    //     },
    //     async error => {
    //       // console.log(error);
    //       this.presentToast(error.error.errors, 'error');
    //     });
    // this.code = 'TES';
    // console.log(this.no_resi, 'this.no_resi');
    // console.log('test')
    // this.saveInbs();
  }

  onCodeResult1(resultString: string) {
    this.qrResultString = resultString;
    // console.log(resultString == '', 'resultString')
    // console.log(resultString != '', '!resultString')
    // console.log(this.qrResultString, 'qrResultString')
    this.no_resi = resultString;
  }

  cariResi(): void {
    // console.log(this.no_resi, 'no_resi');

    // this.no_resi = this.no_resi;
    this.tutorialService
      .get(`inbound/getresi/${this.no_resi}`)
      .subscribe((data: any) => {
        // console.log(data, 'data3')
        // console.log(data.code, 'data.code')
        // console.log(data.data.code == '', 'data.code')
        // console.log(data.data.code != '', 'data.code')
        // console.log(data, 'data')
        this.code = data.data.code;
        this.id_shippers = data.data.id_shippers;
        this.nameshippers = data.data.nameshippers;
        this.id_kurirs = data.data.id_kurirs;
        this.namakurirs = data.data.namakurirs;
        this.tgl_kirim = data.data.tgl_kirim;
        this.id = data.data.id;
        this.status_paket = data.data.status_paket == 'N' ? "Belum di Terima" : "Terima";
        this.namaitems = data.data.namaitems;
        this.namasubitems = data.data.namasubitems;
        this.berat_paket = data.data.berat_paket;
        this.tgl_terima = data.data.tgl_terima;
        this.description = data.data.description;
      },(error: any) => {
        // console.log(error, 'error1');
        this.presentToast(error.error.errors, 'error');
        // if(error.status ==401){
        //   alert("Login or Password not exist");
        // }
      },
        () => {
        console.log("Complete");
      });

    // console.log(this.id, 'this.id');
    // data.status_inbound = 2;
      // data.status_message = 'Setuju';
    // const data = {
    //   id: this.id,
    //   no_resi: this.no_resi,
    //   status_inbound: 2,
    //   status_message: 'Setuju'
    // };

    // this.tutorialService.postData('inbound/approves', data)
    //     .subscribe(
    //       async response => {
    //         // console.log(response);
    //         this.presentToast(response.message, 'success');
    //         this.submitted = true;
    //         this.modalService.dismissAll();
    //         this.retrieveTutorials();
    //       },
    //       async error => {
    //         // console.log(error);
    //         this.presentToast(error.error.errors, 'error');
    //       });
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }
  // END SCAN

  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';

    this.id = null;
    this.pengirim = null;
    this.tgl_kirim = null;
    this.name_kurir = null;
    this.no_resi = null;
    this.name_pengirim = '';
    this.id_shippers = '';
    this.id_kurirs = '';
    this.type_id = {};
    this.subtype_id = {};
    this.status_paket = null;
    this.tgl_terima = null;
    this.berat_paket = null;
    this.isi_paket = null;
    this.status_kurir = null;
    this.status_pickup = null;
    this.status_direct = null;
    this.status_agen = null;
    this.description = null;
    this.type_id = null;
    this.selectedTypeItem = {};
    this.selectedSubTypeItem = {};
    this.selectedFile = '';
    this.selectedFile1 = '';
    this.selectedFile2 = '';
    this.selectedFile3 = '';

    //scan
    this.nameshippers = '';
    this.namakurirs = '';
    this.namaitems = '';

		this.modalService.open(content, { size: 'lg' });
	}

  // START
  onFileSelected(event:any) {
    this.selectedFile = event.target.files[0];
    // console.log(event.target, 'this.selectedFile');
    // console.log(event.target.files, 'this.selectedFile1');
    // console.log(event.target.files.FileList, 'this.selectedFile2');
    // this.selectedFile = event.target.files;
  //   for (var i = 0; i < event.target.files.length; i++) {
  //     this.myFiles.push(event.target.files[i]);
  // }
    // for (var i = 0; i < event.target.files.length; i++) {
    //   this.myFiles.push(event.target.files[i]);
    // }
    // console.log(this.selectedFile, 'this.selectedFile')
    // for(var i=0; i<this.selectedFile.length; i++) {
    //   console.log(this.selectedFile[i].name, 'this.selectedFile[i]')
    //   // formdata.append('fileimage', this.files[i]);
    // }
  }

  onFileSelected1(event:any) {
    this.selectedFile1 = event.target.files[0];
  }
  onFileSelected2(event:any) {
    this.selectedFile2 = event.target.files[0];
  }
  onFileSelected3(event:any) {
    this.selectedFile3 = event.target.files[0];
  }


  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('inbound/view', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  retrieveCombodatashippers(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('shippers/viewshipper', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcombos = data;
        console.log(this.vcombos, 'this.vcombos')
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  retrieveCombodatakurirs(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('kurirs/combodata', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcomboskurir = data;
        console.log(this.vcomboskurir, 'this.vcomboskurir')
      },
      error => {
        this.presentToast(error.error.errors, 'error');
      });
  }

  retrieveCombodatatypeItems(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('typeitems/combodata', params)
    .subscribe(
      response => {
        const { data } = response;
        this.typeItems = data;
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  onAutoCompleteSelected(event: any, tipe: string) {
    const val = {
      value: event,
    };
    this.onDropdownChange(val, tipe);
  }

  onDropdownChange(event: any, tipe: string) {
    // console.log(event.value)
    if(tipe === 'type_id') {
      this.fetchSubItem(event.value.id);
      this.selectedTypeItem = event.value;
    } else if(tipe === 'subtype_id') {
      this.selectedSubTypeItem = event.value;
    }
  }

  onDropdownClear(tipe: string) {
    if(tipe === 'type_id') {
      this.selectedTypeItem = {};
    } else if(tipe === 'subtype_id') {
      this.selectedSubTypeItem = {};
    }
  }

    fetchDropdownValues() {
      const params = this.getRequestParams(this.title, this.page, this.pageSize);
      this.tutorialService.getcombo(`country/combodata`, params).subscribe((response: any) => {
        this.countries = response.data;
      });

  }

  fetchSubItem(event: any) {
    console.log(event, 'mantaps')
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    params.type_id = event

    this.tutorialService.getAlls('typeitemsubs/combodata', params)
    .subscribe(
      response => {
        const { data } = response;
        this.subtypeItems = data;

        // console.log(this.type_id, 'this.type_id i')
        // console.log(this.subtypeItems, 'this.subtypeItems i')
        let subType = this.subtypeItems.find((e:any) => e.id == this.subtype_id);
        console.log(subType, 'subType i')
        this.selectedSubTypeItem = subType;
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    // code: '',
    tgl_kirim: '',
    pengirim: '',
    name_kurir: '',
    no_resi: '',
    id_shippers: '',
    name_pengirim: '',
    id_kurirs: '',
    status_paket: '',
    tgl_terima: '',
    berat_paket: '',
    isi_paket: '',
    status_kurir: '',
    status_pickup: '',
    status_direct: '',
    status_agen: '',
    description: '',
    type_id: '',
    subtype_id: '',
    // name: '',
    // phonecode: '',
    // phone_number: '',
    // address: '',
    // template_option: '',
    // fileimage: '',
    // published: false
  };



  saveAgenmitras(): void {
    // console.log(this.no_resi, 'no_resi');
    // console.log(this.id, 'this.id');
    // data.status_inbound = 2;
      // data.status_message = 'Setuju';
    const data = {
      id: this.id,
      no_resi: this.no_resi,
      status_inbound: 2,
      status_message: 'Setuju'
    };

    this.tutorialService.postData('inbound/approves', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    // const data = {
    //   manifest_bag_id: this.route.snapshot.params['idbag'],
    //   cleansing_id: this.fadd_role.cleansing_id
    // };
    // // console.log(data, 'data');

    // if(this.isCreated) {
    //   this.tutorialService.postData('manifests_bags_detail/create', data)
    //     .subscribe(
    //       async response => {
    //         // console.log(response);
    //         this.fadd_role = {};
    //         this.presentToast(response.message, 'success');
    //         this.submitted = true;
    //         this.modalService.dismissAll();
    //         this.retrieveTutorials();
    //         // this.reloadPage();
    //       },
    //       async error => {
    //         // console.log(error);
    //         this.presentToast(error.error.errors, 'error');
    //       });
    // } else {
    //   this.tutorialService.postData('manifests_bags_detail/update/'+this.fadd_role.id, data)
    //     .subscribe(
    //       async response => {
    //         // console.log(response);
    //         this.fadd_role = {};
    //         this.presentToast(response.message, 'success');
    //         this.submitted = true;
    //         this.modalService.dismissAll();
    //         this.retrieveTutorials();
    //         // this.reloadPage();
    //       },
    //       async error => {
    //         // console.log(error);
    //         this.presentToast(error.error.errors, 'error');
    //       });
    // }
  }


  saveInbs(): void {

    this.isLoading$ = true;

    if(this.isCreated) {

      const formData: FormData = new FormData();
      formData.append('pengirim', this.pengirim);
      formData.append('tgl_kirim', this.tgl_kirim);
      formData.append('name_kurir', this.name_kurir);
      formData.append('no_resi', this.no_resi);
      formData.append('id_shippers', this.id_shippers);
      formData.append('name_pengirim', this.name_pengirim);
      formData.append('id_kurirs', this.id_kurirs);
      formData.append('status_paket', this.status_paket);
      formData.append('tgl_terima', this.tgl_terima);
      formData.append('berat_paket', this.berat_paket);
      formData.append('isi_paket', this.isi_paket);
      formData.append('status_kurir', this.status_kurir);
      formData.append('status_pickup', this.status_pickup);
      formData.append('status_direct', this.status_direct);
      formData.append('status_agen', this.status_agen);
      formData.append('description', this.description);
      formData.append('type_id', this.selectedTypeItem.id);
      formData.append('subtype_id', this.selectedSubTypeItem.id);
      formData.append('fileimage', this.selectedFile);
      formData.append('fileimage1', this.selectedFile1);
      formData.append('fileimage2', this.selectedFile2);
      formData.append('fileimage3', this.selectedFile3);

      this.tutorialService.postData('inbound/create', formData)
      .pipe(
        finalize(() => this.isLoading$ = false)
      )
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      // console.log(data, 'data');
      const formData: FormData = new FormData();
      formData.append('pengirim', this.pengirim);
      formData.append('tgl_kirim', this.tgl_kirim);
      formData.append('name_kurir', this.name_kurir);
      formData.append('no_resi', this.no_resi);
      formData.append('id_shippers', this.id_shippers);
      formData.append('name_pengirim', this.name_pengirim);
      formData.append('id_kurirs', this.id_kurirs);
      formData.append('status_paket', this.status_paket);
      formData.append('tgl_terima', this.tgl_terima);
      formData.append('berat_paket', this.berat_paket);
      formData.append('isi_paket', this.isi_paket);
      formData.append('status_kurir', this.status_kurir);
      formData.append('status_pickup', this.status_pickup);
      formData.append('status_direct', this.status_direct);
      formData.append('status_agen', this.status_agen);
      formData.append('fileimage', this.selectedFile);
      formData.append('fileimage1', this.selectedFile1);
      formData.append('fileimage2', this.selectedFile2);
      formData.append('fileimage3', this.selectedFile3);
      formData.append('description', this.description);
      // formData.append('type_id', this.type_id);
      formData.append('type_id', this.selectedTypeItem.id);
      // formData.append('subtype_id', this.subtype_id);
      formData.append('subtype_id', this.selectedSubTypeItem.id);


      this.tutorialService.postData('inbound/update/'+this.id, formData)
      .pipe(
        finalize(() => this.isLoading$ = false)
      )
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }
  
  saveBySCAN(): void {
    const data = {
      pengirim: this.pengirim,
      tgl_kirim: new Date(),
      name_kurir: this.name_kurir,
      no_resi: this.no_resi,
      id_shippers: this.id_shippers,
      name_pengirim: this.name_pengirim,
      id_kurirs: this.id_kurirs,
      status_paket: this.status_paket,
      tgl_terima: this.tgl_terima,
      berat_paket: this.berat_paket,
      isi_paket: this.isi_paket,
      status_kurir: 'true',
      // status_pickup: this.status_pickup,
      // status_direct: this.status_direct,
      status_agen: this.status_agen,
      description: this.description,
      type_id: this.type_id,
      subtype_id: this.subtype_id,
    };

    // if(this.isCreated) {
      this.isLoading$ = true;

      console.log(this.id_kurirs,'aa')

      const formData: FormData = new FormData();
      formData.append('pengirim', this.pengirim);
      formData.append('tgl_kirim', this.tgl_kirim);
      formData.append('name_kurir', this.name_kurir);
      formData.append('no_resi', this.no_resi);
      formData.append('id_shippers', this.id_shippers);
      formData.append('name_pengirim', this.name_pengirim);
      formData.append('id_kurirs', this.id_kurirs);
      formData.append('status_paket', this.status_paket);
      formData.append('tgl_terima', this.tgl_terima);
      formData.append('berat_paket', this.berat_paket);
      formData.append('isi_paket', this.isi_paket);
      // formData.append('status_kurir', this.status_kurir);
      // formData.append('status_pickup', this.status_pickup);
      // formData.append('status_direct', this.status_direct);
      formData.append('status_agen', this.status_agen);
      formData.append('description', this.description);
      formData.append('type_id', this.selectedTypeItem.id);
      formData.append('subtype_id', this.selectedSubTypeItem.id);
      formData.append('fileimage', this.selectedFile);
      formData.append('fileimage1', this.selectedFile1);
      formData.append('fileimage2', this.selectedFile2);
      formData.append('fileimage3', this.selectedFile3);
  
      this.tutorialService.postData('inbound/createscan', formData)
        .pipe(
          finalize(() => this.isLoading$ = false)
        )
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    // } else {
    //   // console.log(data, 'data');
    //   const formData: FormData = new FormData();
    //   formData.append('pengirim', this.pengirim);
    //   formData.append('tgl_kirim', this.tgl_kirim);
    //   formData.append('name_kurir', this.name_kurir);
    //   formData.append('no_resi', this.no_resi);
    //   formData.append('id_shippers', this.id_shippers);
    //   formData.append('name_pengirim', this.name_pengirim);
    //   formData.append('id_kurirs', this.id_kurirs);
    //   formData.append('status_paket', this.status_paket);
    //   formData.append('tgl_terima', this.tgl_terima);
    //   formData.append('berat_paket', this.berat_paket);
    //   formData.append('isi_paket', this.isi_paket);
    //   formData.append('status_kurir', this.status_kurir);
    //   formData.append('status_pickup', this.status_pickup);
    //   formData.append('status_direct', this.status_direct);
    //   formData.append('status_agen', this.status_agen);
    //   formData.append('fileimage', this.selectedFile);
    //   formData.append('fileimage1', this.selectedFile1);
    //   formData.append('fileimage2', this.selectedFile2);
    //   formData.append('fileimage3', this.selectedFile3);
    //   formData.append('description', this.description);
    //   // formData.append('type_id', this.type_id);
    //   formData.append('type_id', this.selectedTypeItem.id);
    //   // formData.append('subtype_id', this.subtype_id);
    //   formData.append('subtype_id', this.selectedSubTypeItem.id);


    //   this.tutorialService.postData('inbound/update/'+this.id, formData)
    //     .subscribe(
    //       async response => {
    //         // console.log(response);
    //         this.fadd_role = {};
    //         this.presentToast(response.message, 'success');
    //         this.submitted = true;
    //         this.modalService.dismissAll();
    //         this.retrieveTutorials();
    //       },
    //       async error => {
    //         // console.log(error);
    //         this.presentToast(error.error.errors, 'error');
    //       });
    // }
  }

  saveBySCANKONFIRS(): void {
    const data = {
      no_resi: this.no_resi
    };

    this.isLoading$ = true;

    this.tutorialService.postData('inbound/createscankonfirm', data)
      .pipe(
        finalize(() => this.isLoading$ = false)
      )
      .subscribe(
        async response => {
          // console.log(response);
          this.fadd_role = {};
          this.presentToast(response.message, 'success');
          this.submitted = true;
          this.modalService.dismissAll();
          this.retrieveTutorials();
        },
        async error => {
          // console.log(error);
          this.presentToast(error.error.errors, 'error');
        });
  }

  editUser(sendMessageModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    console.log(tutorial, 'tutorial')
    this.modalService.open(sendMessageModal, { size: 'lg' });
    this.id = tutorial.id;
    this.pengirim = tutorial.pengirim;
    this.tgl_kirim = tutorial.tgl_kirim;
    this.name_kurir = tutorial.name_kurir;
    this.no_resi = tutorial.no_resi;
    this.id_shippers = tutorial.id_shippers;
    this.name_pengirim = tutorial.name_pengirim;
    this.id_kurirs = tutorial.id_kurirs;
    this.status_paket = tutorial.status_paket;
    this.tgl_terima = tutorial.tgl_terima;
    this.berat_paket = tutorial.berat_paket;
    this.isi_paket = tutorial.isi_paket;
    this.status_kurir = tutorial.status_kurir == "N" ? this.status_kurir = false:this.status_kurir = true;
    this.status_pickup = tutorial.status_pickup == "N" ? this.status_pickup = false:this.status_pickup = true;
    this.status_direct = tutorial.status_direct == "N" ? this.status_direct = false:this.status_direct = true;
    this.status_agen = tutorial.status_agen == "N" ? this.status_agen = false:this.status_agen = true;
    this.description = tutorial.description;
    this.type_id = tutorial.type_id;
    this.subtype_id = tutorial.subtype_id;
    this.fileimage = tutorial.images;
    this.fileimage1 = tutorial.images1;
    this.fileimage2 = tutorial.images2;
    this.fileimage3 = tutorial.images3;
    this.images = tutorial.images;
    this.imagess = this.inboundImg+tutorial.images;
    this.imagess1 = this.inboundImg+tutorial.images1;
    this.imagess2 = this.inboundImg+tutorial.images2;
    this.imagess3 = this.inboundImg+tutorial.images3;
    // console.log(tutorial, 'tutorial')
    // console.log(this.subtypeItems, 'this.subtypeItems')
    // console.log(this.typeItems, 'this.typeItems')

    let selectedType = this.typeItems.find((e:any) => e.id == tutorial.type_id);
    console.log(selectedType, 'selectedType')
    // this.subtypeItems.find((e:any) => e.id == this.type_id);
    // console.log(selectedType)
    this.selectedTypeItem = selectedType;
    console.log(selectedType.id)
    this.fetchSubItem(selectedType.id);
    // this.code = tutorial.code;
    // this.tgl_kirim = tutorial.tgl_kirim;
    // this.phonecode = tutorial.phonecode;
    // this.phone_number = tutorial.phone_number;
    // this.address = tutorial.address;
    // this.template_option = tutorial.template_option;
    // this.fileimage = tutorial.fileimage;
    // console.log(this.countries)
    // let selectedShipper = this.countries.find((e:any) => e.phonecode == this.phonecode);
    // console.log(selectedShipper)
    // this.selectedShipperPhoneNumberCountry = selectedShipper;
    this.fadd_role.action = action;
  }

  deleteInbound(data:any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`inbound/delete/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  prosesInbound(data:any) {
    var conf = confirm('Anda yakin akan Proses Inbound ini?');
    if (conf) {
      data.status_inbound = 1;
      data.status_message = 'Proses';
      // this.tutorialService.postData('inbound/create', formData)
      this.tutorialService.postData('inbound/approve', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  setujuInbound(data:any) {
    var conf = confirm('Anda yakin akan Setuju Inbound ini?');
    if (conf) {
      data.status_inbound = 2;
      data.status_message = 'Setuju';
      this.tutorialService.postData('inbound/approve', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  tolakInbound(data:any) {
    var conf = confirm('Anda yakin akan Tolak Inbound ini?');
    if (conf) {
      data.status_inbound = 3;
      data.status_message = 'Tolak';
      this.tutorialService.postData('inbound/approve', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  openData(tutorial:any, action:any) {
    console.log(tutorial,'tutorial')
    const param: NavigationExtras = {
      queryParams: {
        info: JSON.stringify(tutorial),
        action: JSON.stringify(action)
      }
    }
    this.router.navigate(['/admin/form-cleansing'], param);
  }

   reloadPage(): void {
    window.location.reload();
  }

  // onDropdownChange(event: any, tipe: string) {
  //   // console.log(event.value)
  //   // if(tipe === 'shipper_phone') {
  //     this.selectedShipperPhoneNumberCountry = event.value;
  //     // console.log('selectedShipperPhoneNumberCountry',this.selectedShipperPhoneNumberCountry);
  //   // }
  // }

  // onDropdownClear(tipe: string) {
  //   // if(tipe === 'shipper_phone') {
  //     this.selectedShipperPhoneNumberCountry = {};
  //   // }
  // }

}
